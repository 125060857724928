import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';

function App() {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const calculateTimeLeft = () => {
    console.log('dato', process.env.REACT_APP_DATE)
    const countdownDate = new Date(process.env.REACT_APP_DATE).getTime();
    console.log('dato', countdownDate)
    const now = new Date().getTime();
    const distance = countdownDate - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds: seconds < 10 ? `0${seconds}` : seconds };
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleClick = () => {
    const phoneNumber = '+573184071803'; // Reemplaza con el número de teléfono que deseas usar
    const message = 'Hola, me encuentro muy interesad@ en la iniciativa Voortex'; // Mensaje predefinido
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    
    window.open(url, '_blank');
  };

  return (

    <Box
      sx={{
        minHeight: '100vh',
        backgroundImage: `url('planeta.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* Fondo gris */}
      < Box
        sx={{
          position: 'absolute',
          width: { xs: '100%', md: '85%' },
          height: { xs: '99%', md: '90%' },
          padding: { xs: '50px', md: '0px' },
          backgroundColor: { xs: '', md: 'rgba(211,211,211,0.92)' },
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'hidden',
          clipPath: {
            md: `
            polygon(10% 0%, 90% 0%, 100% 10%, 100% 90%, 
            90% 100%, 10% 100%, 0% 90%, 0% 10%)
          `},
        }}

      >
        {/* capa imagenes */}
        < Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            zIndex: 2,
            display: { xs: "none", md: 'flex' },
            flexDirection: 'row',
            alignItems: 'flex-end',
            overflow: 'hidden',
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/nubes.png`}
            alt="Imagen 1"
            style={{ width: '100%', height: 'auto', objectFit: 'contain', zIndex: 2, position: "relative" }}
          />
        </Box >

        {/* Corte central */}
        <Box
          sx={{
            position: 'absolute',
            backgroundColor: '#000000',
            clipPath: 'polygon(0% 0%, 5% 100%, 95% 100%, 100% 0%)',
            zIndex: 2,
            width: { xs: '90%', md: '40%', lg: '450px' },
            height: { xs: '50px', md: '80px', lg: '80px' },
            overflow: 'hidden',
            display: { xs: "none", md: 'flex' },
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/VOORTEX LOGO.png`}
            alt="Voortex Logo"
            style={{ width: '80%', height: '90%', objectFit: 'contain' }}
          />
        </Box >
      </Box >

      <Grid container spacing={0} sx={{ position: 'relative', zIndex: 3, maxWidth: { xs: '100%', md: "85%" }, overflow: 'hidden', padding: { xs: "10px", md: "0px" }, marginTop: { xs: "0px", md: "90px" }, marginBottom: { xs: "0px", md: "70px" } }}>
        {/* Sección Izquierda */}
        <Grid item xs={12} md={3.5} sx={{ padding: '10px', display: { xs: "none", md: "flex" } }}>
          <Box>
            <Box sx={{ marginBottom: "25px", marginTop:"15px" }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "600" }}
              >
                ¡Lleva tu negocio al siguiente nivel!
              </Typography>
              <Typography
                variant="h7"
                sx={{
                  color: '#000',
                  display: { xs: "none", md: "flex" }
                }}
              >
                El futuro del dropshipping en Colombia está a punto de comenzar.
              </Typography>
            </Box>
            <Box sx={{ position: 'relative', width: "100%", justifyContent: 'flex-start' }}>
              <img
                src={`${process.env.PUBLIC_URL}/ASTRONAUTA VOORTEX12.png`}
                alt="Profile"
                style={{ width: '115%', height: 'auto', marginTop: '10px', right: '-15%', left: '0', position: 'relative' }}
              />
            </Box>
          </Box>
        </Grid>


        {/* Sección Central */}
        <Grid item xs={12} md={5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '70vh', padding: '10px' }}>
          <Box
            sx={{
              backgroundColor: '#161B2D',
              borderRadius: '25px',
              boxShadow: '0 10px 100px rgba(0, 255, 255, 0.7), 0 0 8px rgba(0, 255, 255, 0.5)',
              textAlign: 'center',
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginTop: { xs: '100px', md: "40px" },
              marginBottom: { xs: '100px', md: "0px" },
              minHeight: { xs: '70vh', md: "45vh" }
            }}
          >
            <Box sx={{ display: { xs: "flex", md: "none" }, position: 'relative', width: '100px', justifyContent: 'flex-start', top: "-90px", left: "-38%" }}>
              <img
                src={`${process.env.PUBLIC_URL}/ASTRONAUTA VOORTEX12.png`}
                alt="Profile"
                style={{ width: '100%', height: 'auto' }}
              />
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" }, justifyContent: "center", alignItems: "center", position: "relative", marginTop: "-80px" }}>
              <img
                src={`${process.env.PUBLIC_URL}/VOORTEX LOGO.png`}
                alt="Voortex Logo"
                style={{ width: '80%', height: '70px', objectFit: 'contain', marginBottom: "25px" }}
              />
            </Box>
            <Typography variant="h4" sx={{ color: '#fff', marginBottom: '0px'}}>
            Alcanza tu máximo potencial
            </Typography>
            <Typography variant="h6" sx={{ color: '#fff', marginBottom: '20px' }}>
              ¡El cambio llega pronto!
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", marginBottom: '20px', width: "85%", margin: "10px" }}>
              <Box
                sx={{
                  marginRight: '3%',
                  backgroundColor: '#fff',
                  padding: '10px',
                  borderRadius: '8px',
                  textAlign: 'center',
                  width: '20%',
                  boxShadow: '0 10px 100px rgba(0, 255, 255, 0.7), 0 0 10px rgba(0, 255, 255, 0.5)',
                }}
              >
                <Typography variant="body1" sx={{ color: '#000', fontWeight: 'bold' }}>
                  {timeLeft.days}
                </Typography>
                <Typography variant="body2" sx={{ color: '#000' }}>
                  Días
                </Typography>
              </Box>
              <Box
                sx={{
                  marginRight: '3%',
                  backgroundColor: '#fff',
                  padding: '10px',
                  borderRadius: '8px',
                  textAlign: 'center',
                  width: '20%',
                  boxShadow: '0 10px 100px rgba(0, 255, 255, 0.7), 0 0 10px rgba(0, 255, 255, 0.5)',
                }}
              >
                <Typography variant="body1" sx={{ color: '#000', fontWeight: 'bold' }}>
                  {timeLeft.hours}
                </Typography>
                <Typography variant="body2" sx={{ color: '#000' }}>
                  Hrs
                </Typography>
              </Box>
              <Box
                sx={{
                  marginRight: '3%',
                  backgroundColor: '#fff',
                  padding: '10px',
                  borderRadius: '8px',
                  textAlign: 'center',
                  width: '20%',
                  boxShadow: '0 10px 100px rgba(0, 255, 255, 0.7), 0 0 10px rgba(0, 255, 255, 0.5)',
                }}
              >
                <Typography variant="body1" sx={{ color: '#000', fontWeight: 'bold' }}>
                  {timeLeft.minutes}
                </Typography>
                <Typography variant="body2" sx={{ color: '#000' }}>
                  Min
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: '#fff',
                  padding: '10px',
                  borderRadius: '8px',
                  textAlign: 'center',
                  width: '20%',
                  boxShadow: '0 10px 100px rgba(0, 255, 255, 0.7), 0 0 10px rgba(0, 255, 255, 0.5)',
                }}
              >
                <Typography variant="body1" sx={{ color: '#000', fontWeight: 'bold' }}>
                  {timeLeft.seconds}
                </Typography>
                <Typography variant="body2" sx={{ color: '#000' }}>
                  Sec
                </Typography>
              </Box>
            </Box>

            <Button
              variant="contained"
              sx={{
                background: "linear-gradient(to right, #0064FB, #1ECDF8)",
                color: '#fff',
                fontSize: '14px',
                marginTop: "20px",
                borderRadius: '50px',
                padding: '15px 30px',
              }}
              onClick={handleClick}
            >
              ¡Quiero más info!
            </Button>

            <Box sx={{ marginTop: '20px', display: { xs: "flex", md: "none" } }}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px", color: "#f7f7f7" }}>Próximamente!</Typography>
            </Box>
          </Box>

          {/* Contenido adicional al final */}
          <Box sx={{ marginTop: '20px', display: { xs: "none", md: "flex" } }}>
            <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>Próximamente!</Typography>
          </Box>

        </Grid>

        {/* Sección Derecha */}
        <Grid item xs={12} md={3.5} sx={{ padding: '10px', display: { xs: "none", md: "flex" } }}>
          <Box>
            <Box sx={{marginBottom: "25px", marginTop:"15px"}}>
              <Typography variant="h5" sx={{ color: '#000', fontWeight: 'bold' }}>
                ¡Multiplica tus ventas y alcanza el éxito!
              </Typography>
              <Typography variant="h7" sx={{ color: '#000'}}>
                Publica, gestiona, y vende más con nuestra plataforma.
              </Typography>
            </Box>
            <Box sx={{ position: 'relative', width: '100%', justifyContent: 'flex-start' }}>
              <img
                src={`${process.env.PUBLIC_URL}/Capa14.png`}
                alt="Top Seller"
                style={{ width: '150%', height: 'auto', marginTop: '10px', left: '-30%', right: '0', position: 'relative' }}

              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box >

  );
}

export default App;
